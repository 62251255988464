import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Itinerary } from 'src/app/model/itinerary.object';
import { Order } from 'src/app/model/order.object';
import { Obligation } from 'src/app/model/obligation.object';
import { Subscription, take } from 'rxjs';
import { LocalJsonService } from 'src/app/service/local-json.service';
import { AlertService } from 'src/app/service/alert.service';
import { FilesService } from 'src/app/service/files.service';
import { OrderService } from 'src/app/service/order.service';
import { ObligationService } from 'src/app/service/obligation.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-itinerary-diary',
  templateUrl: './itinerary-diary.component.html',
  styleUrls: ['./itinerary-diary.component.css'],
  providers: [
    FilesService,
    LocalJsonService,
    OrderService,
    ObligationService
  ]
})
export class ItineraryDiaryComponent implements OnInit, OnDestroy {

  private _subscribed: Array<Subscription> = [];

  public itinerary: Array<Itinerary> = [];

  private _order: Order | null = null;
  @Input()
  public set order(value: Order | null) {
    this._order = value;
    if (this._order && this._order.obligations && this._order.obligations.length) {
      this.itinerary = [];
      this.attachments = [];
      
      if (this._order.order_comp_book && this._order.order_comp_book.country) {
        this.country = this._order.order_comp_book.country;
        if (!['CZ', 'SK', 'EN', 'DE', 'IT', 'ES', 'PL'].includes(this.country)) {
          // out of our dictionary - use EN
          this.country = 'EN';
        }
      }

      this._order.obligations.forEach(
        ob => {
          // init attachments
          if (ob.attachments) {
            this.attachments = this.attachments.concat(ob.attachments);
          }
          // init itinerary
          ob.itinerary.forEach(
            (it: Itinerary) => {
              it.obligation = ob;
            }
          );
          this.itinerary = this.itinerary.concat(ob.itinerary);
        }
      );

      // download thumbnails
      this.attachments.forEach(
        a => {
          this.downloadThumbnail(a);
        }
      )
    }
  }
  public get order(): Order | null {
    return this._order;
  }

  private _obligation: Obligation | null = null;
  @Input()
  public set obligation(value: Obligation | null) {
    this._obligation = value;
    if (this._obligation) {
      if (this._obligation.order_comp_book && this._obligation.order_comp_book.country) {
        this.country = this._obligation.order_comp_book.country;
        if (!['CZ', 'SK', 'EN', 'DE', 'IT', 'ES', 'PL'].includes(this.country)) {
          // out of our dictionary - use EN
          this.country = 'EN';
        }
      }

      // init attachments
      this.attachments = [];
      if (this._obligation.attachments) {
        this.attachments = this._obligation.attachments;
        // download thumbnails
        this.attachments.forEach(
          a => {
            this.downloadThumbnail2(a);
          }
        );
      }
      // init itinerary
      if (this._obligation.itinerary) {
        this._obligation.itinerary.forEach(
          (it: Itinerary) => {
            if (this._obligation) {
              it.obligation = this._obligation;
            }
          }
        );
        this.itinerary = this._obligation.itinerary;
      }
    }
  }
  public get obligation(): Obligation | null {
    return this._obligation;
  }

  private _token: string | null = null;
  @Input()
  public set token(value: string | null) {
    this._token = value;
  }
  public get token(): string | null {
    return this._token;
  }
  
  public attachments: Array<any> = [];

  public orderDictionary: any = null;
  public diaryDictionary: any = null;
  public country: string = 'CZ';
  public showDocuments: boolean = false;


  @Output() completeItineraryShow: EventEmitter<Itinerary> = new EventEmitter<Itinerary>();
  @Output() uploadFileEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  
  @Output() closePreviewModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() geolocationErrorEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() geolocationStringEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() geolocationModalEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _obligationServ: ObligationService,
    private _localJsonServ: LocalJsonService,
    private _orderServ: OrderService,
    private _filesServ: FilesService,
    private _alertServ: AlertService
  ) {
  }

  ngOnInit(): void {
    this._subscribed.push(
      this._localJsonServ.getOrderDictionary().subscribe({
        next: (response) => {
          this.orderDictionary = response;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert('Nepodařilo se načíst slovník.', 'danger', 4000);
        }
      }),
      this._localJsonServ.getDiaryDictionary().subscribe({
        next: (response) => {
          this.diaryDictionary = response;
        },
        error: (err) => {
          console.log(err);
          this._alertServ.alert('Nepodařilo se načíst slovník.', 'danger', 4000);
        }
      })
    );
  }

  ngOnDestroy() {
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
    this._subscribed = [];
  }


  /***********************************************/
  /* Obligation attachments part */
  /***********************************************/
  get externalAttachments(): Array<any> {
    // external origin + no origin (currently uploaded files)
    return this.attachments.filter(
      a => a.origin == 'EXTERNAL' || !a.origin
    );
  }

  get webTMAttachments(): Array<any> {
    // external origin + no origin (currently uploaded files)
    return this.attachments.filter(
      a => a.origin == 'WEB_TM'
    );
  }

  droppedFile(event: any): void {
    if (event.target && event.target.files instanceof FileList) {
      let files = event.target.files;
      let token = this._token;

      // order (using in ext-order or in message from whatsapp ext-order)
      if (this.order) {
        let order = this.order.order_number_standard;
        let obligation: string | null = null; 
        if (this.order.obligations && this.order.obligations.length) {
          obligation = this.order.obligations[0].order_number_standard;
        }

        for (var i = 0; i < files.length; i++) {
          let file = files[i];
          // PDF/images/texts straight attachment upload
          if (file.type !== '' && (file.type.match(/image|pdf|text/))) {
            // log only on localhost
            if (!window.location.href.match(/ext2.truckmanager.eu/)) {
              console.log(order);
            }

            this._subscribed.push(
              this._orderServ.uploadAttachmentToObligation(token, order, obligation, file).subscribe({
                next: (response) => {
                  // log only on localhost
                  if (!window.location.href.match(/ext2.truckmanager.eu/)) {
                    console.log(response);
                  }
                  this._alertServ.alert(
                    file.name + ' ' + this.orderDictionary['FilePostOk'][this.country], 'success', 4000
                  );
                  this.uploadFileEvent.emit(true);
                },
                error: (err) => {
                  console.log(err);
                  this._alertServ.alert(
                    this.orderDictionary['FilePostError'][this.country] + ' ' + file.name, 'danger', 4000
                  );
                }
              })
            );
          } 
          else {
            this._subscribed.push(
              this._filesServ.convertToPdf(file, token).subscribe(
                (pdfResponse: HttpResponse<any>) => {
                  let newFile: File = new File(
                    [pdfResponse.body], 
                    file.name.substring(0, file.name.lastIndexOf('.')) + '.pdf'
                  );
                  this._subscribed.push(
                    this._orderServ.uploadAttachmentToObligation(token, order, obligation, newFile).subscribe({
                      next: (response) => {
                        // log only on localhost
                        if (!window.location.href.match(/ext2.truckmanager.eu/)) {
                          console.log(response);
                        }
                        this._alertServ.alert(
                          file.name + ' ' + this.orderDictionary['FilePostOk'][this.country], 'success', 4000
                        );
        
                        this.uploadFileEvent.emit(true);
                      },
                      error: (err) => {
                        console.log(err);
                        this._alertServ.alert(
                          this.orderDictionary['FilePostError'][this.country] + ' ' + file.name, 'danger', 4000
                        );
                      }
                    })
                  );
                }
              )
            );
          }
          event.target.value = null;
        }
      }
      // obligation using in ext-itinerary-diary - our drivers on iPhone (web TM)
      else if (this.obligation) {
        let obligation: string | null = null; 
        if (this.obligation) {
          obligation = this.obligation.order_number_standard;
        }

        for (var i = 0; i < files.length; i++) {
          let file = files[i];
          // PDF/images/texts straight attachment upload
          if (file.type !== '' && (file.type.match(/image|pdf|text/))) {
            this._subscribed.push(
              this._obligationServ.uploadAttachmentToObligation(token, obligation, file).subscribe({
                next: (response) => {
                  // log only on localhost
                  if (!window.location.href.match(/ext2.truckmanager.eu/)) {
                    console.log(response);
                  }
                  this._alertServ.alert(
                    file.name + ' ' + this.orderDictionary['FilePostOk'][this.country], 'success', 4000
                  );
                  this.uploadFileEvent.emit(true);
                },
                error: (err) => {
                  console.log(err);
                  this._alertServ.alert(
                    this.orderDictionary['FilePostError'][this.country] + ' ' + file.name, 'danger', 4000
                  );
                }
              })
            );
          } 
          else {
            this._subscribed.push(
              this._filesServ.convertToPdf(file, token).subscribe(
                (pdfResponse: HttpResponse<any>) => {
                  let newFile: File = new File(
                    [pdfResponse.body], 
                    file.name.substring(0, file.name.lastIndexOf('.')) + '.pdf'
                  );
                  this._subscribed.push(
                    this._obligationServ.uploadAttachmentToObligation(token, obligation, newFile).subscribe({
                      next: (response) => {
                        // log only on localhost
                        if (!window.location.href.match(/ext2.truckmanager.eu/)) {
                          console.log(response);
                        }
                        this._alertServ.alert(
                          file.name + ' ' + this.orderDictionary['FilePostOk'][this.country], 'success', 4000
                        );
                        this.uploadFileEvent.emit(true);
                      },
                      error: (err) => {
                        console.log(err);
                        this._alertServ.alert(
                          this.orderDictionary['FilePostError'][this.country] + ' ' + file.name, 'danger', 4000
                        );
                      }
                    })
                  );
                }
              )
            );
          }
          event.target.value = null;
        }
      }
    }
  }

  downloadThumbnail(attachment: any): void {
    if (attachment.url) {
      let thumbUrl: string = '';
      if (attachment.url) {
        if (attachment.url.includes('?name')) {
          thumbUrl = attachment.url.substring(0, attachment.url.indexOf('?name'));
        }
        else {
          thumbUrl = attachment.url;
        }
      }
      if (thumbUrl) { 
        // add /thumb
        thumbUrl = thumbUrl.replace('company/', 'company/thumb/');
      }

      this._subscribed.push(
        this._filesServ.getThumbnail(thumbUrl, this._token).subscribe({
          next: (response) => {
            let newBlob: any = new Blob([response], { type: response.type });
    
            if (response.type !== '' && (response.type.match(/image|pdf|text/))) {
              attachment.thumbnail = {
                content: URL.createObjectURL(newBlob),
                type: response.type,
                blob: newBlob
              };
            }
          },
          error: (error) => {
            // set flag after error loading
            console.log(error);
          }
        })
      );
    }
  }

  downloadThumbnail2(attachment: any): void {
    if (attachment.url) {
      let url: string = '';
      if (attachment.url) {
        if (attachment.url.includes('?name')) {
          url = attachment.url.substring(0, attachment.url.indexOf('?name'));
        }
        else {
          url = attachment.url;
        }
      }
      url = attachment.name;
      let token = this._token;
      
      // obligation using in ext-itinerary-diary - our drivers on iPhone (web TM)
      if (this.obligation) {
        let obligation: string | null = this.obligation.order_number_standard;

        this._subscribed.push(
          this._obligationServ.getThumbnailAttachment(url, token, obligation).subscribe({
            next: (response) => {
              let newBlob: any = new Blob([response], { type: response.type });
    
              if (response.type !== '' && (response.type.match(/image|pdf|text/))) {
                attachment.thumbnail = {
                  content: URL.createObjectURL(newBlob),
                  type: response.type,
                  blob: newBlob
                };
              }
            },
            error: (error) => {
              // set flag after error loading
              console.log(error);
            }
          })
        );
      }
    }
  }

  downloadFile(attachment: any): void {
    if (attachment.url) {
      let url: string = '';
      // if (attachment.url) {
      //   if (attachment.url.includes('?name')) {
      //     url = attachment.url.substring(0, attachment.url.indexOf('?name'));
      //   }
      //   else {
      //     url = attachment.url;
      //   }
      // }
      url = attachment.name;
      let token = this._token;
      
      // order (using in ext-order or in message from whatsapp ext-order)
      if (this.order) {
        let order = this.order.order_number_standard;
        let obligation: string | null = null; 
        if (this.order.obligations && this.order.obligations.length) {
          obligation = this.order.obligations[0].order_number_standard;
        }

        this._subscribed.push(
          this._orderServ.getAttachmentFromObligation(url, token, order, obligation).subscribe({
            next: (response) => {
              this.openAttachment(response);
            },
            error: (error) => {
              // set flag after error loading
              console.log(error);
            }
          })
        );
      }
      // obligation using in ext-itinerary-diary - our drivers on iPhone (web TM)
      else if (this.obligation) {
        let obligation: string | null = null; 
        if (this.obligation) {
          obligation = this.obligation.order_number_standard;
        }

        this._subscribed.push(
          this._obligationServ.getAttachmentFromObligation(url, token, obligation).subscribe({
            next: (response) => {
              this.openAttachment(response);
            },
            error: (error) => {
              // set flag after error loading
              console.log(error);
            }
          })
        );
      }
    }
  }

  openAttachment(d: any): void {
    if (d) {
      var newBlob = new Blob([d], { type: d.type ? d.type : "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && (navigator as any).msSaveOrOpenBlob) {
        (navigator as any).msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      // var link = document.createElement('a');
      // link.href = data;
      // link.download = attachment.name;
      // // this is necessary as link.click() does not work on the latest firefox
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      // setTimeout(function () {
      //     // For Firefox it is necessary to delay revoking the ObjectURL
      //     window.URL.revokeObjectURL(data);
      //     link.remove();
      // }, 100);

      // open new tab (does not work when ad-block is activated)
      window.open(data, '_blank');
    }
  }
  

  /***********************************************/
  /* Geolocation */
  /***********************************************/

  handleGeolocation(): void {
    this.closePreviewModal.emit(true);

    if (!navigator.geolocation) {
      console.error('Váš prohlížeč nepodporuje navigator.Geolocation');
      this.geolocationErrorEvent.emit(true);
      this.geolocationStringEvent.emit('Váš prohlížeč nepodporuje navigator.Geolocation');
      this.geolocationModalEvent.emit(true);
    }
    else if (navigator.geolocation) {
      if (navigator.permissions) {
        navigator.permissions.query({name:'geolocation'}).then(
          result => {
            if (result.state === 'granted') {
              // Permission already granted, call getCurrentPosition
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  console.log(position);
                  const longitude = position.coords.longitude;
                  const latitude = position.coords.latitude;
                  console.log(latitude + ',' + longitude);
                  this.geolocationErrorEvent.emit(false);
                  this.geolocationStringEvent.emit('Vaše poloha: ' + latitude + ',' + longitude);
                  this.geolocationModalEvent.emit(true);

                  // testing interval
                  window.setInterval(
                    () => {
                      navigator.geolocation.getCurrentPosition(
                        (position) => {
                          console.log(position);
                          const longitude = position.coords.longitude;
                          const latitude = position.coords.latitude;
                          console.log(latitude + ',' + longitude);
                          this.geolocationErrorEvent.emit(false);
                          this.geolocationStringEvent.emit('Vaše poloha: ' + latitude + ',' + longitude);
                        }
                      );
                    }, 15000
                  );
                },
                error => {
                  console.log(error);
                  this.geolocationErrorEvent.emit(true);
                  this.geolocationStringEvent.emit('Váš prohlížeč zablokoval poskytnutí polohy.');
                  this.geolocationModalEvent.emit(true);
                  // this.geolocationStringEvent.emit('Chyba při metodě getCurrentPosition.\n' + error + '\nError-code: ' + error.code + '\nError-message: ' + error.message);
                }
              );
            } 
            else if (result.state === 'prompt') {
              // Permission not granted, ask the user for permission
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  console.log(position);
                  const longitude = position.coords.longitude;
                  const latitude = position.coords.latitude;
                  console.log(latitude + ',' + longitude);
                  this.geolocationErrorEvent.emit(false);
                  this.geolocationStringEvent.emit('Vaše poloha: ' + latitude + ',' + longitude);
                  this.geolocationModalEvent.emit(true);

                  // testing interval
                  window.setInterval(
                    () => {
                      navigator.geolocation.getCurrentPosition(
                        (position) => {
                          console.log(position);
                          const longitude = position.coords.longitude;
                          const latitude = position.coords.latitude;
                          console.log(latitude + ',' + longitude);
                          this.geolocationErrorEvent.emit(false);
                          this.geolocationStringEvent.emit('Vaše poloha: ' + latitude + ',' + longitude);
                        }
                      );
                    }, 15000
                  );
                },
                error => {
                  console.log(error);
                  this.geolocationErrorEvent.emit(true);
                  this.geolocationStringEvent.emit('Váš prohlížeč zablokoval poskytnutí polohy.');
                  this.geolocationModalEvent.emit(true);
                  // this.geolocationStringEvent.emit('Chyba při metodě getCurrentPosition.\n' + error + '\nError-code: ' + error.code + '\nError-message: ' + error.message);
                },
                {
                  enableHighAccuracy: true,
                  timeout: 5000,
                  maximumAge: 0
                }
              );
            } 
            else {
              // Permission denied
              console.error('Povolení zamítnuto vaším prohlížečem/zařízením.');
              this.geolocationErrorEvent.emit(true);
              this.geolocationStringEvent.emit('Povolení zamítnuto vaším prohlížečem/zařízením.');
              this.geolocationModalEvent.emit(true);
            }
          }
        );
      } 
      else {
        // Browser does not support navigator.permissions
        console.error('Prohlížeč nepodporuje navigator.permissions.');
        this.geolocationErrorEvent.emit(true);
        this.geolocationStringEvent.emit('Prohlížeč nepodporuje navigator.permissions.');
        this.geolocationModalEvent.emit(true);
      }
    }
  }


  /***********************************************/
  /* Scan images patterns */
  /***********************************************/
  openImgPattern(img_src: string | null): void {
    if (img_src) {
      window.open(img_src, '_blank');
    }
  }
}
